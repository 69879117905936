(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Vue.component("portfolio-skills", {
  template: "\n    <ul class=\"c-list--unstyled nh-skills-list u-flex u-flex-wrap\">\n     <li \n      v-for=\"skill in skills\" \n      :class=\"'nh-bg-' + skill[0].color\"\n      class=\"c-badge u-medium\">\n        {{ skill[0].name }}\n      </li>\n    </ul>\n  ",
  data: function data() {
    return {
      skills: [[{
        name: "HTML",
        color: "pink"
      }], [{
        name: "JavaScript",
        color: "lime"
      }], [{
        name: "React",
        color: "lime"
      }], [{
        name: "Ant Design",
        color: "lime"
      }], [{
        name: "Gatsby",
        color: "lime"
      }], [{
        name: "jQuery",
        color: "lime"
      }], [{
        name: "CSS3",
        color: "blue-pale"
      }], [{
        name: "SASS",
        color: "blue-pale"
      }], [{
        name: "LESS",
        color: "blue-pale"
      }], [{
        name: "Bootstrap",
        color: "blue-pale"
      }], [{
        name: "Responsive",
        color: "blue-pale"
      }], [{
        name: "Pattern Libraries",
        color: "grey"
      }], [{
        name: "Git",
        color: "orange"
      }], [{
        name: "Gitlab",
        color: "orange"
      }], [{
        name: "Gulp",
        color: "lime-light"
      }], [{
        name: "Jekyll",
        color: "lime-light"
      }], [{
        name: "Twig",
        color: "lime-light"
      }], [{
        name: "Hugo",
        color: "lime-light"
      }], [{
        name: "Squarespace",
        color: "pink-light"
      }], [{
        name: "Wordpress",
        color: "pink-light"
      }], [{
        name: "PHP",
        color: "pink-light"
      }], [{
        name: "Sitecore",
        color: "coral"
      }], [{
        name: "Magnolia",
        color: "coral"
      }], [{
        name: "Optimizely",
        color: "coral"
      }]]
    };
  }
});
Vue.component("portfolio-examples", {
  template: "\n    <ul class=\"c-list c-list--unstyled \">\n      <li \n        :id=\"projectIds[index]\"\n        class=\"work-item\"\n        v-for=\"(example, index) in examples\" \n        >\n\n        <div class=\"o-grid__cell\">\n          <h4 class=\"c-heading\">{{ example.name }}  </h4>\n        </div>\n        <div class=\"o-grid o-grid--small-full o-grid--medium-full\">\n          <div class=\"o-grid__cell\">\n            <span v-html=\"example.description\"></span>\n            <ul class=\"c-list c-list--unstyled c-list--comma \">\n             <li \n              v-for=\"(exampleSkill, index) in example.skills\" \n              class=\"c-list__item\"\n              >{{ exampleSkill }}</li>\n            </ul>\n          </div>\n          \n          <div class=\"o-grid__cell\">\n            <a :href=\"example.url\" target=\"_blank\"><img :src=\"example.image\" class=\"o-image\" :alt=\"example.name\"></a>\n            <img v-if=\"example.image2\" :src=\"example.image2\" class=\"o-image \" :alt=\"example.name\" style=\"margin-top: 20px\">\n          </div>\n        </div>\n      </li>\n    </ul>\n  ",
  data: function data() {
    return {
      examples: [{
        name: "OnMed",
        url: "https://onmed.com/",
        image: "lib/images/portfolio/sm/onmed.jpg",
        description: "<p>The was a redesign of OnMed's site.</p> \n              <p>I developed this with Gatsby and React as the headless front end. Because Gatsby creates a static site, it loads incredibly quickly, even with Wordpress as the CMS. It's the best of both worlds.</p>",
        skills: ["React", "Gatsby", "Wordpress", "JavaScript", "HTML", "SASS", "CSS", "Git", "Gulp"]
      }, {
        name: "Versant",
        url: "#",
        image: "lib/images/portfolio/sm/versant.jpg",
        image2: "lib/images/portfolio/sm/versant-alt.jpg",
        description: "<p>Uses APIs to bypass restrictions of core banking development environments for a React application.</p> \n                    <p>To share core UI and functionality but allow partial brand customization for each client I intercepted LESS variables within React before they were compiled for Ant Design's UI library. For the rest of the application, I created SASS architecture to share core styles and among all clients, and do minimal SASS variable overriding for each client.",
        skills: ["React", "Ant Design", "HTML", "SASS", "LESS", "CSS", "JavaScript", "Gitlab"]
      }, {
        name: "Northwest Bank",
        url: "https://www.northwest.bank/",
        image: "lib/images/portfolio/sm/northwest.jpg",
        description: "<p>A redesign and rearchitecture of their 140 page site.</p> \n                    <p>As the front end lead, I translated the design system into <a href=\"https://pl.nathanhiemstra.com/\" target=\"_blank\">reusable modules, CSS patterns, and eight page templates</a>. Myself and the rest of our team then integrated into React in the Optimizely CMS and built out the entire site.</p>",
        skills: ["React", "HTML", "SASS", "LESS", "CSS", "JavaScript", "jQuery", "Gitlab", "Episerver"]
      }, {
        name: "Greenhouse Autions",
        image: "lib/images/portfolio/sm/greenhouse.jpg",
        description: "<p>We designed and developed this new art auction platform that focuses on benefiting artists, galleries, and donates portions to local charities.</p> \n                    <p>I translated this ambitions design into reusable modules and patterns in Wordpress. I did significant customizing to the Ultimate WordPress Auction Plugin to run the auctions the way the founder and designern envisioned.</p>",
        skills: ["HTML", "PHP", "SASS", "CSS", "JavaScript", "jQuery", "Git", "Wordpress", "Advanced Custom Fields"]
      }, {
        name: "Avon",
        url: "http://avon.nathanhiemstra.com/",
        image: "lib/images/portfolio/sm/avon.jpg",
        description: "<p>We designed, developed, and executed Avon\u2019s first on-time product launch in 15 years. </p> \n              <p>I lead a team of developers who delivered a <a href=\"http://avon.nathanhiemstra.com/\" target=\"_blank\" class=\"c-link\">style guide and pattern library</a>, built on Bootstrap, and customized using Atomic Design methodology. Our team directed Avon IT to implement this complete redesign into their system. </p>",
        skills: ["HTML", "SASS", "CSS", "JavaScript", "jQuery", "Git", "Gulp", "Twig", "Deploybot"]
      }, {
        name: "Nissan Commercial Vehicles",
        url: "https://www.nissanusa.com/nissan-commercial-vehicles.html",
        image: "lib/images/portfolio/sm/nissan.jpg",
        description: " <p>I led a team of developers in model-year updates, implementation of new features, and continual maintenance of Nissan Commercial Vehicle\u2019s website. </p>\n              <p>An initiative I took was creating a command line script that reduced the multiple-day task of exporting 2500 images for the model year update under one hour. This handled variances of four vehicle models, interior and exterior shots, 10 colors, and multiple sizes\u2014 all for their 8-frame, <a href=\"https://www.nissanusa.com/vehicles/commercial/nv-cargo/gallery.html\" target=\"_blank\" class=\"c-link\">360 degree rotating image</a>.</p>",
        skills: ["HTML", "SASS", "CSS", "JavaScript", "jQuery", "Git", "Bash"]
      }, {
        name: "Van Buren Youth Camp",
        url: "http://vbyc.org",
        image: "lib/images/portfolio/sm/vbyc.jpg",
        description: "<p>I led UI, UX, and the copywriting team on this pro bono redesign. We developed a Wordpress theme and built the full solution on Bootstrap.</p>",
        skills: ["HTML", "SASS", "CSS", "Bootstrap", "PHP", "Wordpress", "JavaScript", "jQuery"]
      }, {
        name: "United Airlines",
        image: "lib/images/portfolio/sm/united.jpg",
        description: "<p>We migrated United Agent\u2019s command-line interface to a modern, scalable GUI. </p>\n              <p>I led a team of developers who created a style guide and module library that served as a code delivery method. We worked closely with United IT to implement this into their system.</p>",
        skills: ["HTML", "SASS", "CSS", "JavaScript", "jQuery", "Git", "Jekyll"]
      }, {
        name: "Humana",
        url: "https://www.humana.com/",
        image: "lib/images/portfolio/sm/humana.jpg",
        description: "<p>The first responsive and accessible redesign of Humana.com.</p>\n              <p>Our team worked along side Humana\u2019s developers to implement module patterns into Humana\u2019s Sitecore and build out pages.</p>",
        skills: ["HTML", "SASS", "CSS", "JavaScript", "jQuery", "Sitecore"]
      }]
    };
  },
  computed: {
    projectIds: function projectIds() {
      var allIds = [];
      var thisName;
      this.examples.forEach(function (example) {
        thisName = example.name;
        thisName = thisName.replace(/\s+/g, "-").toLowerCase();
        allIds.push(thisName);
      });
      return allIds;
    },
    allProjects: function allProjects() {
      var allNamesArr = [];
      this.examples.forEach(function (example) {
        allNamesArr.push(example.name);
        allNames = allNamesArr.join(", ");
      });
      return allNames;
    }
  }
});
var app = new Vue({
  el: "#app"
});

},{}]},{},[1]);
